import { parameters } from './leaf-core.js';
import {executeIfMapExist} from "pnp_core_bundle/modules/map/function";

 /**
 * Add marker on map
 * @param coords
 */
export function addMarker (coords) {
     executeIfMapExist(() => {
         if (typeof coords !== 'undefined') {
             if(JKisio('img.leaflet-marker-icon').length > 0) {
                 JKisio('img.leaflet-marker-icon').remove();
             }
             const icon = parameters.L.icon({
                 iconUrl: parameters.urlImages + 'map-marker-from.svg',
                 iconSize: parameters.icon_size
             });
             const object_coord = coords.split(';');
             if (typeof object_coord !== 'undefined' && Object.keys(object_coord).length > 1) {
                 let layerSectionName = 'markerAdd';
                 if (!Kisio.layerGroup.journey.hasOwnProperty(layerSectionName)) {
                     Kisio.layerGroup.journey[layerSectionName] = new L.layerGroup();
                 }
                 Kisio.layerGroup.journey[layerSectionName].addLayer(parameters.L.marker([object_coord[0], object_coord[1]], {icon: icon, keyboard: false}));
                 Kisio.layerGroup.journey[layerSectionName].addTo(window.leaflet.map);
                 window.leaflet.map.setView([object_coord[0], object_coord[1]]);
             }
         }
     });
};